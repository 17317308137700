//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import { getFocus } from '../../../mixins/getFocus'

export default {
  name: 'Bareme',
  mixins: [getFocus],
  data: function () {
    return {
      focus: []
    }
  },
  async beforeMount () {
    await this.getFocus('Tolus Immo', 'Bareme')
      .then(res => {
        this.focus = res
      })
  }
}
